<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-stock-wholesale-clients' }">{{
            $t("STOCK_WHOLESALE_CLIENTS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingStock"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            v-if="!firstLoader"
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>

          <SkeletonLoaderMain :visible="firstLoader"></SkeletonLoaderMain>
          <v-overlay :value="!firstLoader && isLoadingStock">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingStock"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_CLIENT } from "@/modules/stock/store/stock.module";
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "ClientAdd",
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      firstLoader: true,
      currencies: {},
      vatGroups: {}
    };
  },
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Clients", route: { name: "list-stock-wholesale-clients" } },
      { title: "Add Client" }
    ]);
    vm.generateFormOutOfSchemaJson(this.schemaJson);
    vm.getSelects();

    vm.$nextTick(function() {
      vm.firstLoader = false;
    });
  },
  watch: {},
  computed: {
    ...mapGetters(["isLoadingStock", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "input",
            inputType: "number",
            id: "stock-wholesale-client-number-input",
            groupId: "stock-wholesale-client-number-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            i18n: {
              label: "NUMBER",
              placeholder: "NUMBER",
              validation: "VALID_NUMBER"
            },
            validations: {
              required: true,
              minLength: 1
            },
            translatable: false,
            model: "number"
          },
          {
            type: "input",
            inputType: "text",
            id: "stock-wholesale-client-name-input",
            groupId: "stock-wholesale-client-name-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            i18n: {
              label: "NAME",
              placeholder: "NAME",
              validation: "VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "name"
          },
          {
            type: "input",
            inputType: "text",
            id: "stock-wholesale-client-bulstat-input",
            groupId: "stock-wholesale-client-bulstat-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            i18n: {
              label: "BULSTAT",
              placeholder: "BULSTAT",
              validation: "VALID_BULSTAT"
            },
            validations: {},
            translatable: false,
            model: "bulstat"
          },
          {
            type: "input",
            inputType: "text",
            id: "stock-wholesale-client-vat_number-input",
            groupId: "stock-wholesale-client-vat_number-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            i18n: {
              label: "VAT_NUMBER",
              placeholder: "VAT_NUMBER",
              validation: "VALID_VAT_NUMBER"
            },
            validations: {},
            translatable: false,
            model: "vatNumber"
          },
          {
            type: "input",
            inputType: "text",
            id: "stock-wholesale-client-owner-input",
            groupId: "stock-wholesale-client-owner-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            i18n: {
              label: "OWNER",
              placeholder: "OWNER",
              validation: "VALID_OWNER"
            },
            validations: {},
            translatable: false,
            model: "owner"
          },
          {
            type: "input",
            inputType: "text",
            id: "stock-wholesale-client-address-input",
            groupId: "stock-wholesale-client-address-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: true,
            i18n: {
              label: "ADDRESS",
              placeholder: "ADDRESS",
              validation: "VALID_ADDRESS"
            },
            validations: {},
            translatable: false,
            model: "address"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "stock-wholesale-client-group",
            id: "stock-wholesale-client-input",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            clearable: false,
            i18n: {
              label: "CURRENCY",
              placeholder: "CURRENCY"
            },
            options: this.currencies,
            validations: {
              required: true
            },
            model: "currencyId"
          },
          {
            type: "select",
            inputType: "select",
            id: "stock-wholesale-client-vat_rate-input",
            groupId: "stock-wholesale-client-vat_rate-group",
            required: "required",
            feedback_id: "stock-wholesale-client-live-feedback",
            divider: false,
            i18n: {
              label: "VAT_RATE",
              placeholder: "VAT_RATE"
            },
            options: this.vatGroups,
            validations: {},
            translatable: false,
            model: "vatGroupId"
          },
          {
            type: "switch",
            groupId: "item-isForeign-group",
            id: "item-isForeign-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "isForeign-live-feedback",
            colSm: "auto",
            divider: true,
            default: 0,
            i18n: {
              label: "IS_FOREIGN",
              placeholder: "IS_FOREIGN"
            },
            validations: {},
            model: "isForeign"
          }
        ]
      };
    }
  },
  validations() {
    let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    const passwordValidations = {
      required: true,
      minLength: 6
    };
    if ("password" in vm.form) {
      tmpValidationObject.form["password"] = vm.setItemValidations(
        passwordValidations
      );
      return tmpValidationObject;
    } else {
      return tmpValidationObject;
    }
  },
  methods: {
    getSelects() {
      let vm = this;
      let payload = {
        currencies: {
          fields: ["id", "name", "code"],
          search: ""
        },
        vat_groups: {
          fields: ["id", "name", "percent"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.currencies = data.data.currencies;
          vm.form.currencyId = vm.currencies[0].id;
          vm.vatGroups = data.data.vat_groups;
          vm.form.vatGroupId = vm.vatGroups[0].id;
        })
        .catch(response => {
          console.log(response);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      this.$store
        .dispatch(SAVE_CLIENT, this.form)
        .then(data => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-stock-wholesale-client",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-stock-wholesale-client" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-stock-wholesale-clients" });
          }
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    resetVForm() {
      this.$v.$reset();
    }
  }
};
</script>
